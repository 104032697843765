module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Napa Valley Electrology & Skin Care","short_name":"Napa Valley Electrology","description":"An integrated approach to skin health. Permanent hair removal via electrolysis + clinical skin care treatments. Gender Affirming and surgery prep services provided with compassion and understanding.","lang":"en","start_url":"/","icon":"src/images/NVEIcon.png","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"bb892b84c647bf098a3253a61125ec4d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
